import { Os } from '../../generated/graphql';

export interface EhrConfig {
  schemaName: string;
  os: Os[] | undefined;
  disabledEhrUrl: boolean;
}

export type EhrConfigObject = Record<string, EhrConfig>;

export const ehrSystemsConfig: EhrConfigObject = {
  ecw: {
    schemaName: 'ecw',
    os: [Os.Web, Os.Desktop],
    disabledEhrUrl: false,
  },
  athena: {
    schemaName: 'athena',
    os: [Os.Web],
    disabledEhrUrl: false,
  },
  'practice-fusion': {
    schemaName: 'practice-fusion',
    os: [Os.Web],
    disabledEhrUrl: true,
  },
  allscripts: {
    schemaName: 'allscripts',
    os: [Os.Web, Os.Desktop],
    disabledEhrUrl: false,
  },
  'office-ally': {
    schemaName: 'office-ally',
    os: [Os.Sdk],
    disabledEhrUrl: false,
  },
  'dr-chrono': {
    schemaName: 'dr-chrono',
    os: [Os.Web],
    disabledEhrUrl: false,
  },
  elation: {
    schemaName: 'elation',
    os: [Os.Web],
    disabledEhrUrl: true,
  },
  'mock-ehr': {
    schemaName: 'mock-ehr',
    os: [Os.Web, Os.Sdk],
    disabledEhrUrl: false,
  },
  'Nextgen Enterprise': {
    schemaName: 'nextgen',
    os: [Os.Desktop],
    disabledEhrUrl: true,
  },
  care360: {
    schemaName: 'care360',
    os: [Os.Web],
    disabledEhrUrl: true,
  },
  kareo: {
    schemaName: 'kareo',
    os: [Os.Web],
    disabledEhrUrl: false,
  },
  simulator: {
    schemaName: 'simulator',
    os: [Os.Web],
    disabledEhrUrl: false,
  },
  vimulator: {
    schemaName: 'vimulator',
    os: [Os.Web],
    disabledEhrUrl: true,
  },
  epic: {
    schemaName: 'epic',
    os: [Os.Desktop],
    disabledEhrUrl: false,
  },
  'teladoc-core': {
    schemaName: 'teladoc-core',
    os: [Os.Sdk],
    disabledEhrUrl: false,
  },
  'teladoc-salesforce': {
    schemaName: 'teladoc-salesforce',
    os: [Os.Sdk],
    disabledEhrUrl: false,
  },
  mdland: {
    schemaName: 'mdland',
    os: [Os.Web],
    disabledEhrUrl: true,
  },
};

export const ehrSystemConfigsLabel: Record<string, string> = {
  nextgen: 'Nextgen Enterprise',
};
