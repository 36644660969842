import { transformEhrUrl } from '@getvim/ehr-url-manipulation';
import { kebabCase } from 'lodash-es';
import short from 'short-uuid';
import { Organization, OrganizationTheme } from '../../generated/graphql';
import { ehrSystemsConfig } from './utils';

export const postSubmitOrgTransform = (
  org: Organization,
  isSSO?: boolean | null,
  shouldRemoveStrictFromURLTransformersFF?: boolean,
): Organization => {
  const { slug, isSeamlessLoginAutoApproved, tin, tins, ...transformedOrg }: Organization = {
    ...org,
    organizationKey: org.organizationKey || short.generate(),
    theme: org.theme ?? OrganizationTheme.VimConnect,
    ehrUrl: transformEhrUrl(org.ehrUrl || '', org.ehrSystem || '', {
      strict: shouldRemoveStrictFromURLTransformersFF ? false : true,
    }),
    config: {
      ...org.config,
      schemaName: ehrSystemsConfig?.[org.ehrSystem || '']?.schemaName,
    },
    ssoConnectionName: isSSO ? org.ssoConnectionName || kebabCase(org.name || '') : null,
  };
  const tinObject = { tins: tins?.filter((n) => !!n) };

  return {
    ...transformedOrg,
    ...tinObject,
  };
};
