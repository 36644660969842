import { useState, useEffect } from 'react';
import { useAuth0Wrapper } from '../useAuth0Wrapper';

export function useEnableInvestigatorPage(): boolean {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0Wrapper();
  const [enableInvestigatorPage, setEnableInvestigatorPage] = useState(false);
  const VIM_INVESTIGATOR_PERMISSION = 'vim:investigator';

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  };

  useEffect(() => {
    const shouldEnableInvestigatorPage = async () => {
      const token = await getAccessTokenSilently();
      const { permissions } = parseJwt(token);

      setEnableInvestigatorPage(permissions.includes(VIM_INVESTIGATOR_PERMISSION));
    };

    if (isAuthenticated) {
      shouldEnableInvestigatorPage();
    }
  }, []);

  return enableInvestigatorPage;
}
